export const environment = {
  TYPE: "DEV",
  production: false,
  composerLogic: "https://platform-dev.astrakode.tech/rc/projects",
  composerLogicLocal: "https://platform-dev.astrakode.tech/rc/projects",
  accessKeyId: "AKIAUEFRLLESGDZ7SI45",
  secretAccessKey: "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",
  region: "eu-central-1",
  cognito: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_nS3G56iAG",
    aws_user_pools_web_client_id: "7kqr14djuqd1j5is1jogn4b7fo",
    oauth: {
      domain: "astrakodeauth-dev.auth.eu-central-1.amazoncognito.com",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://platform-dev.astrakode.tech/",
      redirectSignOut: "https://platform-dev.astrakode.tech/",
      responseType: "code",
    },
  },
  GEOLOCATION_API: "https://ipapi.co/json/",
  API_URL: "https://platform-dev.astrakode.tech/AstraKodeWS/",
  HOME_URL: "https://platform-dev.astrakode.tech/",
  TRACES_SAMPLE_RATE: 0.5,
  SENTRY_DSN: "https://o491380.ingest.sentry.io/5557941",
  TRACING_ORIGIN: ["localhost", "https://platform-dev.astrakode.tech/"],
  STRIPE_TOKEN:
    "pk_test_51JuFatERyYwgTtqEJpMlgUOCCwVPg0nVjMmR0jVHqcr5b2bMLe2D1kZu5uRDTuGaJfaRDzsO9XLx8Qw3ENPuuYk400ATtZuLRP",

  LEAD_URL: "https://app.leadbi.com/api/v1/",
  LEAD_ID: "974fddf6-172f-467e-bc1b-5329926fae2b",
  LEAD_TOKEN:
    "06f752fe4bf7e3f236e230c41e41d4d9369d52cd38ab4eaba00a1f0e4a250fae3e97ca3176192e3bbd1f51ef8d37a6bebbdbf32d4a12375c589b8e495e968d98",
  LEAD_APP: 969,
  //CAPTCHA_TOKEN: "6LdFFiMaAAAAADy48vLlzP8Fexd3b_RCJAiGF86K",
  CAPTCHA_SITEKEY: "6Lecqn4mAAAAAB8OJLrF7tNDgKcOgp1mMMGns_pn",
  NO_PAIR_TYPE: "NoPair",
};
